<template>
  <div>
    <div class="" style="width: 100%">
      <img style="display: block;width: 1920px;margin: auto;" src="../../assets/images/djt/dzpxfw.jpg" alt="">
    </div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c13="" class="page1">
            <h2 _ngcontent-c13="" class="center">定制培训服务介绍</h2>
            <p _ngcontent-c13="" class="em2">交通运输行业网络安全大讲堂在交通运输行业网络安全攻防训练基地的总体规划和指导下，拥有专业的讲师及平台技术支持团队，平台课程丰富，可根据企事业单位的不同需求提供定制化培训服务。</p>
            <p _ngcontent-c13="">定制化培训优势：</p>
            <p _ngcontent-c13="" class="em2 bold">· 培训形式多样</p>
            <p _ngcontent-c13="" class="em2">在线培训：提供实时直播授课与点播视频课程，打破地域、空间限制</p>
            <p _ngcontent-c13="" class="em2">面授培训：既可由平台委派资深讲师到企业内部进行现场培训，也可根据培训需求由平台组织面向单一企业、多企业或某一行业的专题培训会</p>
            <p _ngcontent-c13="" class="em2 bold">· 学习方式灵活</p>
            <p _ngcontent-c13="" class="em2">集中学习：由培训单位规定具体培训时间，平台提供讲师课程，采用面授或直播形式，方便培训单位统一学习、统一管理</p>
            <p _ngcontent-c13="" class="em2">分散学习：平台提供直播回访及点播视频课程，学员可根据自身工作安排，将原有按天为单位的课时，拆分为小时课程，自主安排学习进度</p>
            <p _ngcontent-c13="" class="em2 bold">· 精准匹配需求</p>
            <p _ngcontent-c13="" class="em2">定制化科目：根据管理、运维、技术等不同岗位，设计更为有针对性的课程</p>
            <p _ngcontent-c13="" class="em2">定制化班型：可为大型企业提供本企业内训，减轻企业组织培训工作量，也可为中小型企业提供多家企业联合培训，降低人员培训成本</p>
          </div>
    </div>
      </div>
    </div>
  </div>

  
</template>

<style lang="less" scoped>
  .bold {
    font-weight: bold;
  }
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2{
  text-indent: 2em;
  line-height: 40px;
}
.center{
  text-align: center;
}
.img {
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
.img img {
  width: 600px;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
